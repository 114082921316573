"use strict";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "jquery";
import "foundation-sites";
import Rails from "@rails/ujs";
import svg4everybody from "svg4everybody";
import morphdom from "morphdom";
import "src/decidim/input_tags";
import "src/decidim/input_hashtags";
import "src/decidim/input_mentions";
import "src/decidim/input_multiple_mentions";
import "src/decidim/input_autojump";
import "src/decidim/history";
import "src/decidim/callout";
import "src/decidim/clipboard";
import "src/decidim/append_elements";
import "src/decidim/user_registrations";
import "src/decidim/account_form";
import "src/decidim/append_redirect_url_to_modals";
import "src/decidim/form_attachments";
import "src/decidim/form_remote";
import "src/decidim/delayed";
import "src/decidim/vizzs";
import "src/decidim/responsive_horizontal_tabs";
import "src/decidim/security/selfxss_warning";
import "src/decidim/session_timeouter";
import "src/decidim/confirm";
import "src/decidim/results_listing";
import "src/decidim/impersonation";
import "src/decidim/gallery";
import "src/decidim/direct_uploads/upload_field";
import "src/decidim/data_consent";
import "src/decidim/abide_form_validator_fixer";
import "src/decidim/sw";
import "src/decidim/sticky_header";
import "src/decidim/attachments";
import formDatePicker from "src/decidim/datepicker/form_datepicker";
import Configuration from "src/decidim/configuration";
import ExternalLink from "src/decidim/external_link";
import updateExternalDomainLinks from "src/decidim/external_domain_warning";
import scrollToLastChild from "src/decidim/scroll_to_last_child";
import InputCharacterCounter, { createCharacterCounter } from "src/decidim/input_character_counter";
import FormValidator from "src/decidim/form_validator";
import FormFilterComponent from "src/decidim/form_filter";
import addInputEmoji, { EmojiButton } from "src/decidim/input_emoji";
import FocusGuard from "src/decidim/focus_guard";
import backToListLink from "src/decidim/back_to_list";
import markAsReadNotifications from "src/decidim/notifications";
import handleNotificationActions from "src/decidim/notifications_actions";
import RemoteModal from "src/decidim/remote_modal";
import selectActiveIdentity from "src/decidim/identity_selector_dialog";
import createTooltip from "src/decidim/tooltips";
import createToggle from "src/decidim/toggle";
import {
  createAccordion,
  createDialog,
  createDropdown,
  announceForScreenReader,
  Dialogs
} from "src/decidim/a11y";
import changeReportFormBehavior from "src/decidim/change_report_form_behavior";
window.Decidim = window.Decidim || {
  config: new Configuration(),
  ExternalLink,
  InputCharacterCounter,
  FormValidator,
  addInputEmoji,
  EmojiButton,
  Dialogs,
  announceForScreenReader
};
window.morphdom = morphdom;
window.initFoundation = (element) => {
  $(element).foundation();
  const $document = $(document);
  $document.off("click.zf.trigger", window.Foundation.Triggers.Listeners.Basic.openListener);
  $document.on("click.zf.trigger", "[data-open]", (ev, ...restArgs) => {
    var _a;
    const accordion = (_a = ev.currentTarget) == null ? void 0 : _a.closest("[data-component='accordion']");
    if (accordion) {
      return;
    }
    Reflect.apply(window.Foundation.Triggers.Listeners.Basic.openListener, ev.currentTarget, [ev, ...restArgs]);
  });
};
Rails.start();
const initializer = (element = document) => {
  window.focusGuard = window.focusGuard || new FocusGuard(document.body);
  window.initFoundation(element);
  svg4everybody();
  element.querySelectorAll('input[type="datetime-local"],input[type="date"]').forEach((elem) => formDatePicker(elem));
  element.querySelectorAll(".editor-container").forEach((container) => window.createEditor(container));
  $("input[type='text'], textarea, .editor>input[type='hidden']", element).each((_i, elem) => {
    const $input = $(elem);
    if (!$input.is("[minlength]") && !$input.is("[maxlength]")) {
      return;
    }
    createCharacterCounter($input);
  });
  $("form.new_filter", element).each(function() {
    const formFilter = new FormFilterComponent($(this));
    formFilter.mountComponent();
  });
  element.querySelectorAll('a[target="_blank"]:not([data-external-link="false"])').forEach((elem) => {
    updateExternalDomainLinks(elem);
    return new ExternalLink(elem);
  });
  addInputEmoji(element);
  backToListLink(element.querySelectorAll(".js-back-to-list"));
  markAsReadNotifications(element);
  handleNotificationActions(element);
  scrollToLastChild(element);
  element.querySelectorAll('[data-component="accordion"]').forEach((component) => createAccordion(component));
  element.querySelectorAll('[data-component="dropdown"]').forEach((component) => createDropdown(component));
  element.querySelectorAll("[data-dialog]").forEach((component) => createDialog(component));
  element.querySelectorAll("[data-dialog-remote-url]").forEach((elem) => new RemoteModal(elem));
  element.querySelectorAll("[data-user-identity]").forEach((elem) => selectActiveIdentity(elem));
  element.querySelectorAll("[data-tooltip]").forEach((elem) => createTooltip(elem));
  element.querySelectorAll("[data-toggle]").forEach((elem) => createToggle(elem));
  element.querySelectorAll(".new_report").forEach((elem) => changeReportFormBehavior(elem));
};
$(() => initializer());
document.addEventListener("remote-modal:loaded", ({ detail }) => initializer(detail));
document.addEventListener("ajax:loaded", ({ detail }) => initializer(detail));
document.addEventListener("comments:loaded", (event) => {
  const commentsIds = event.detail.commentsIds;
  if (commentsIds) {
    commentsIds.forEach((commentId) => {
      const commentsContainer = document.getElementById(`comment_${commentId}`);
      if (commentsContainer) {
        initializer(commentsContainer);
      }
    });
  }
});
